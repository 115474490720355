/*
 * @Descripttion: 验收单
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-14 23:07:57
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-14 23:08:42
 */
import request from "../request"
// 验收单详情
export const orderDetail = function (data) {
    return request.post('/service/getServiceDetail', data)
}

// 确认验收单
export const confirmOrder = function (data) {
    return request.post('/service/confirmService', data)
}