<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-17 22:05:46
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-08-23 22:21:28
-->
<template>
  <div class="page-box">
    <div class="card">
      <memo-box :title="'工单id:' + info.number">
        <div slot="body" class="deital">
          <div class="row" data-title="品牌区域">
            <span>{{ info.brandAreaName }}</span>
          </div>
          <div class="row" data-title="店铺名称">
            <span>{{ info.shopName }}</span>
          </div>
          <div class="row" data-title="门店地址">
            <span>{{ info.address }}</span>
          </div>
          <div class="row" data-title="联 系 人">
            <span>{{ info.contactPerson }}</span>
          </div>
          <div class="row" data-title="联系电话">
            <span>{{ info.contactMobile }}</span>
          </div>
          <div class="row" data-title="报修项目">
            <span>{{ info.repairTypeName }}</span>
          </div>
          <div class="row" data-title="报修类型">
            <span class="tag" v-if="!info.repairLevel">非紧急</span>
            <span class="tag tag--wrong" v-else>紧急</span>
          </div>
          <div class="row" data-title="上门维修">
            <span>{{ info.serviceTime }}</span>
          </div>
          <div class="row" data-title="勘察时间">
            <span>{{ info.surveyTime }}</span>
          </div>
          <div class="row" data-title="登高工具">
            <span>{{ info.isAscend == 0 ? "无" : "有" }}</span>
          </div>
          <div class="row" data-title="报修描述">
            <span class="intro">{{ info.description }}</span>
          </div>
          <div class="row" data-title="拍照图片">
            <ul class="imgs">
              <li
                class="img-box"
                v-for="(item, index) in info.photo"
                :key="index"
                @click="$previewImgs(info.photo)"
              >
                <img :src="item" alt="" />
              </li>
            </ul>
          </div>

          <div class="row" data-title="目前状态">
            <span>{{ checkStatus(info, orderStatus).name }}</span>
          </div>

          <div v-if="servicePhoto.length || serviceRemark">
            <div class="row__line"></div>

            <div class="row" data-title="备注" v-if="serviceRemark">
              <span>{{ serviceRemark }}</span>
            </div>

            <div class="row" data-title="维修照片" v-if="servicePhoto.length">
              <ul class="imgs">
                <li
                  class="img-box"
                  v-for="(item, index) in servicePhoto"
                  :key="index"
                  @click="$previewImgs(servicePhoto)"
                >
                  <img :src="item" alt="" />
                </li>
              </ul>
            </div>
            <div class="row" data-title="维修报告" v-if="serviceReport.length">
              <ul class="imgs">
                <li
                  class="img-box"
                  v-for="(item, index) in serviceReport"
                  :key="index"
                  @click="$previewImgs(serviceReport)"
                >
                  <img :src="item" alt="" />
                </li>
              </ul>
            </div>
          </div>

          <div class="btns flex-x center between">
            <div
              class="btn"
              v-for="(item, index) in checkStatus(info, orderStatus).btns || []"
              :key="index"
              :class="item.type === 'primary' ? 'btn--confirm' : ''"
              @click="clickBtn(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </memo-box>
    </div>

    <msg-box v-model="showNone" @onclick="$router.back()" />

    <turn-down
      v-model="showTurnDown"
      @onsubmit="submitTurnDown"
      ref="turnDown"
    ></turn-down>
  </div>
</template>

<script>
import { Toast } from "vant";
import msgBox from "../../../components/msgBox/msgBox";
import turnDown from "../../../components/turnDown/turnDown.vue";
import MemoBox from "@/components/MemoBox/MemoBox";
// import {
//   orderDetail,
//   order,
//   fillTime,
//   settlementAmount,
// } from "../../../utils/api/service";

import { orderDetail as serviceOrderDetail } from "../../../utils/api/acceptance";

// import { orderDetail } from "../../../utils/api/workOrder";
import {
  orderList,
  confirmOrder,
  orderDetail,
} from "../../../utils/api/otherWorkOrder";
import { confirm as confirmQuotation } from "../../../utils/api/quotation";
import { confirmOrder as confirmAcceptance } from "../../../utils/api/acceptance";
export default {
  components: {
    "memo-box": MemoBox,
    "msg-box": msgBox,
    "turn-down": turnDown,
  },

  data() {
    return {
      info: { review: {} }, // 订单详情

      serviceRemark: "", // 维修备注
      servicePhoto: [], // 维修图片
      serviceReport: [], // 维修报告

      showNone: false, // 显示没有列表

      showTurnDown: false, // 显示驳回弹窗

      prepareInfo: 0, // 准备操作的内容
      prepareBtnInfo: {}, // 准备操作的按钮信息

      amount: 0, // 结算金额

      orderStatus: [
        {
          name: "待审核",
          condition: { otherStatus: 0 },
          btns: [
            {
              name: "驳回",
              method: "turnDown",
              api: confirmOrder,
              idKey: "id",
            },
            {
              name: "通过",
              type: "primary",
              method: "pass",
              api: confirmOrder,
              idKey: "id",
            },
          ],
        },
        {
          name: "待审核",
          condition: { otherStatus: 1 },
          btns: [
            {
              name: "驳回",
              method: "turnDown",
              api: confirmQuotation,
              idKey: "label",
            },
            {
              name: "通过",
              type: "primary",
              method: "pass",
              api: confirmQuotation,
              idKey: "label",
            },
          ],
        },
        {
          name: "待审核",
          condition: { otherStatus: 2 },
          btns: [
            {
              name: "驳回",
              method: "turnDown",
              api: confirmAcceptance,
              idKey: "label",
            },
            {
              name: "通过",
              type: "primary",
              method: "pass",
              api: confirmAcceptance,
              idKey: "label",
            },
          ],
        },
      ],
    };
  },

  created() {
    this.getDetail();
  },

  methods: {

    // 获取订单详情
    getDetail() {
      orderDetail({ id: this.$route.query.id })
        .then((res) => {
          let info = res.data.data;
          this.info = info;
          // 获取维修单
          if (info.otherStatus === 2 && info.label) {
            this.getServiceOrder(info.label);
          }
        })
        .catch((res) => {
          this.$router.back();
          // Toast(res.data.msg);
        });
    },

    // 获取维修单
    getServiceOrder(id) {
      serviceOrderDetail({
        id,
      })
        .then((res) => {
          this.servicePhoto = res.data.data.service.photo;
          this.serviceReport = res.data.data.service.report;
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 检查转台
    checkStatus(info, status) {
      for (let i = 0; i < status.length; i++) {
        let condition = status[i].condition;

        if (this.checkCondition(info, condition)) {
          return status[i];
        }
      }
      return "";
    },

    // 检查条件内容
    checkCondition(obj, condition) {
      let checkPassed = true; // 检查通过
      for (let j in condition) {
        let position = j.split(".");
        let value = obj;
        while (position.length > 0) {
          value = value[position[0]];
          position.splice(0, 1);
        }
        if (condition[j] !== value) {
          checkPassed = false;
          break;
        }
      }
      return checkPassed;
    },

    // 点击按钮
    clickBtn(btn) {
      console.log(btn);
      if (!btn.method) return false;
      this[btn.method](this.info.id, this.info, btn);
    },

    // 驳回
    turnDown(id, info, btn) {
      console.log(id, info, btn);
      this.prepareBtnInfo = btn;
      this.prepareInfo = info;
      this.showTurnDown = true;
    },

    // 发送驳回 工单 报价单 维修单
    submitTurnDown(e) {
      this.prepareBtnInfo
        .api({
          id: this.prepareInfo[this.prepareBtnInfo.idKey],
          review: 2,
          reason: e.detail.text,
        })
        .then((res) => {
          Toast.success("驳回成功");
          this.showTurnDown = false;
          this.$refs.turnDown.clear();
          this.$router.back();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 通过订单 报价单 验收单
    pass(id, info, btn) {
      btn
        .api({
          id: info[btn.idKey],
          review: 1,
        })
        .then((res) => {
          Toast.success("通过成功");
          this.$router.back();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 查看报价
    viewOffer(id, info) {
      this.$router.push({
        name: "ReviewOffer",
        query: {
          id: info.label,
        },
      });
    },

    // 提交报告
    submitReport(id) {
      this.$router.push({
        name: "ServiceReport",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style src="@/assets/css/review/newTicketDetail.css" scoped>
</style>
<style src="@/assets/css/review/newTicketDetail_pc.css" scoped>
</style>