<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 15:33:28
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-02 14:52:47
-->
<template>
  <div>
    <popup v-model="syncShow" class="popup flex-y center">
      <div class="icon" :style="`background-image: url('${icon}');`"></div>
      <div class="title" v-if="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
      <div class="btn" @click="clickBtn">退出</div>
    </popup>
  </div>
</template>

<script>
import { Popup } from "vant";
export default {
  props: {
    icon: {
      type: String,
      default: require("../../assets/images/review/msg_success.png"),
    },
    // 标题
    title: String,
    // 内容
    content: {
      type: String,
      default: "暂时没搜索到可用订单",
    },
    // 显示
    value: Boolean,
  },
  components: {
    popup: Popup,
  },

  data() {
    return {
      show: false,

      syncShow: this.value,
    };
  },

  methods: {
    // 点击按钮
    clickBtn() {
      this.$emit("onclick");
    },
  },

  watch: {
    syncShow(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.syncShow = newVal;
    },
  },
};
</script>

<style scoped>
.popup {
  width: 100vw;
  height: 100vh;
}

.icon {
  width: 327px;
  height: 327px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 153px;
}

.title {
  font-size: 52px;
  color: #222;
  margin-top: 125px;
  font-weight: bold;
  white-space: pre-line;
  text-align: center;
}

.content {
  font-size: 32px;
  margin-top: 41px;
  color: #222;
}

.title + .content {
  margin-top: 28px;
}

.btn {
  width: 646px;
  height: 78px;
  background-color: var(--main-color);
  color: white;
  font-size: 32px;
  text-align: center;
  line-height: 78px;
  border-radius: 39px;
  margin-top: 314px;
}

.title ~ .btn {
  margin-top: 150px;
}

@media screen and (min-width: 900px) {
  .icon {
    width: 20vw;
    height: 20vw;
    margin: 5vw auto 0;
  }

  .content {
    font-size: 3vw;
  }

  .btn {
    height: 5vw;
    line-height: 5vw;
    font-size: 3vw;
    margin: 5vw auto 0;
  }
}
</style>